@import '../_colors';

.index-wrapper{
  margin-top: 0px !important;
  width: 100%;
  height: 100vh;
  background-color: $app-section-background;
  .index-wrapper-row{
    height: 100%;
    width: 100%;
    max-height: 560px;
    max-width: 1170px;
    box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
  }
}

.login-controls{
  background-color: $app-primary;
}

.logo{
  margin-bottom: 25px;
}

.qr-code{
  margin-top: 25px;
  padding:10px;
  background-color: white;
}

.header{
  text-align: center;
  width: 300px;
}

.login-btn{
  button{
    max-width: 180px;
    border-radius: 40px 40px 40px 40px;
    border: 1px solid white;
    background-color: white;
    color: $app-primary;
    &:active{
      background-color: $app-primary;
    }
  }
}

.info{
  padding-left: 50px;
  padding-top: 30px;
}

.heading{
  border-bottom: 2px solid $app-primary;
  width: 40px;
  margin-bottom: 40px;
  h1{
    font-size: 24px;
    margin-bottom: 16px;
    width: 375px;
  }
}

.important{
  margin-bottom: 20px;
  h5{
    font-size: 18px;
    margin-bottom: 25px;
  }
  p{
    a{
      color: $app-primary;
    }
  }
}

.resources{
  h5{
    font-size: 18px;
    margin-bottom: 25px;
  }
}

.stores{
  max-width: 250px;
  margin-left: -15px;
  .store{
    width: 55px;
    height: 55px;
    border-radius: 15px;
    a{
      text-align: center;
    }
    &:hover{
      color: white;
      background-color: $app-primary;
      fa-icon{
        color: white;
      }
    }
  }
  fa-icon{
    color: grey;
    font-size: 35px;
    text-align: center;
  }
}
