@media screen and (max-width: 768px){
  .index-wrapper{
    height: 100%;
    .row{
      max-height: unset;
    }
  }

  .login-controls{
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .info{
    padding-left: 15px;
    justify-content: center;
  }

  .heading{
    border-bottom: none;
    width: 100%;
    h1{
      font-size: 16px;
      margin-bottom: 16px;
      text-align: center;
      width: unset;
    }
  }

  .important{
    text-align: center;
    h5{
      font-size: 14px;
    }
  }

  .stores{
    margin-left: auto;
  }

  .resources{
    h5{
      text-align: center;
      font-size: 14px;
    }
  }
}
